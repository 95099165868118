const config = {
  siteTitle: 'Bin Kim',
  navLinks: [
    { name: 'Bio', link: '/bio/'},
    { name: 'Experiences', link: '/experiences/'},
    { name: 'Clips', link: '/clips'},
  ]
}

module.exports = config;
